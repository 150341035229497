import React from 'react'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import { brand } from 'src/styles/colors'

const SaibaMais = ({ url, color = brand.primary, dataLayer }: { url: string; color?: string; dataLayer: IDataLayerParams}) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <a
      onClick={() => {
      sendDatalayerEvent(dataLayer)
      }}
      href={url} className='fs-16 lh-20 fw-700 d-flex align-items-center' style={{ color: color }}
    >
      Saiba mais
      <IconsSwitch lib='interco' customPath='' icon='action-navigation/ic_arrow_right' width='20' height='20' color={color} />
    </a>
  )
}

export default SaibaMais
